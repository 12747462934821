<template>
    <div>

    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {
        localStorage.setItem("token", this.$route.query.token);
        localStorage.setItem("ms_username", this.$route.query.account);
        localStorage.setItem("gender", this.$route.query.gender);
        localStorage.setItem("realName", this.$route.query.realName);
        this.$router.push("/home");
    },
    methods: {

    }
}
</script>

<style></style>