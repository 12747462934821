<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>巡检记录</el-breadcrumb-item>
      <el-breadcrumb-item>工段级</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="10">
        <el-col :span="4">
          <el-select
            v-model="queryParams.lineCode"
            placeholder="请选择路线"
            clearable
            @change="selectCheckLine"
          >
            <el-option
              v-for="item in checkLineList"
              :key="item.lineCode"
              :label="item.lineName"
              :value="item.lineCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-date-picker
            v-model="timeRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="triggerDate">
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-select
            v-model="queryParams.checkStatus"
            placeholder="巡检状态"
            clearable
          >
          <el-option
              label="未巡检"
              :value="0"
            ></el-option>
            <el-option
              label="暂存"
              :value="1"
            ></el-option>
            <el-option
              label="已巡检"
              :value="2"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button 
            type="primary" 
            icon="el-icon-search"
            @click="getCheckLogsDate"
            >查询</el-button
          >
          <el-button
            @click="exportData"
            style="margin-left: 10px"
            type="success"
            icon="el-icon-download"
            >导出</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="5" style="margin-top: 10px">
      <el-col :span="3">
        <el-card>
          <div slot="header">
            <span>巡检日志</span>
          </div>
          <div class="logs-list">
            <div v-for="item in checkDateList" :key="item.id" class="date-record" @click="selectCurrentDateFun(item.checkDate)">
              {{item.checkDate}}
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div slot="header">
            <span>巡检项</span>
          </div>
          <div class="check-list">
            <!-- 表格数据 -->
            <el-table :show-header="false" :data="checkRecords" border stripe>
              <el-table-column label="">
                <template slot-scope="scope">
                  <v-check-record-item :data="scope.row" @showRecordDetail="centerthis"></v-check-record-item>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 分页组件 -->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryParams.pageNo"
              :page-size="queryParams.pageSize"
              layout="total, prev, pager, next"
              :total="total"
              :pager-count="3"
            >
            </el-pagination>
        </el-card>
      </el-col>
      <el-col :span="15">
        <el-card>
          <div class="marker-map">
            <v-check-log-map :data="currentSelectLine" ref="logMapRef" :canEdit="false" @onShowLogDetail="showLogDetail"></v-check-log-map>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-drawer
      v-if="checkLogDetailDrawer"
      title="详细信息"
      :visible.sync="checkLogDetailDrawer"
      size="40%"
      append-to-body>
      <v-check-detail ref="logdetailRef" :logdetail="selectLogdetail"></v-check-detail>
    </el-drawer>
  </div>
</template>

<script>
import vCheckRecordItem from "./CheckRecordItem.vue";
import vCheckLogMap from "./PcsLinesMap.vue";
import vCheckDetail from "../checkdetail/CheckDetail.vue";
export default {
  components: {
    vCheckRecordItem,
    vCheckLogMap,
    vCheckDetail
  },
  data () {
    return {
      showDownloadVisible: false,
      checkLogDetailDrawer: false,
      queryParams: {
        projectCode: "",
        lineCode: "",
        pageNo: 1,
        pageSize: 10,
        checkBeginDate: null,
        checkEndDate: null,
        checkStatus: 2
      },
      total: 0,
      projectInfoList: [],
      checkLineList: [],
      timeRange: [],
      checkDateList: [],
      checkRecords: [],
      selectLogdetail: {},
      currentSelectLine: {},
      currentSelectDate: null
    }
  },
  created () {
    this.getAllProject();
  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.loadCheckItems(this.currentSelectDate);
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.loadCheckItems(this.currentSelectDate);
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
        if (this.projectInfoList != null) {
          this.queryParams.projectCode = this.projectInfoList[0].projectCode;
          this.loadCheckLines();
        }
      }
    },
    async loadCheckLines() {
      const { data: res } = await this.$http.get("pro/check/find/line/rolename", {
        params: {
          projectCode: this.queryParams.projectCode,
          roleName: '工段级'
        }
      });
      if (res.code == 0 && res.data !== null) {
        this.checkLineList = res.data;
        if (this.checkLineList.length > 0) {
          this.currentSelectLine = this.checkLineList[0];
          this.queryParams.lineCode = this.checkLineList[0].lineCode;
          this.loadLastWeekData()
        }
      }
    },
    // 获取过去一个星期的数据
    loadLastWeekData() {
      var today = new Date();
      // 天数所表示的毫秒
      var dateOffset = 30 * 24 * 60 * 60 * 1000;
      var startDate = new Date(today.getTime() - dateOffset);
      this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', startDate))));
      this.timeRange.push(new Date(Date.parse(this.common.todayDateStr('YYYY-MM-dd HH:mm:ss', today))));
      this.getCheckLogsDate();
    },
    async getCheckLogsDate() {
      if (!this.triggerDate(this.timeRange)) {
        return;
      }

      if (this.timeRange != null && this.timeRange.length > 0) {
        this.queryParams.checkBeginDate = this.common.dateFormat('YYYY-MM-dd', this.timeRange[0]);
        this.queryParams.checkEndDate = this.common.dateFormat('YYYY-MM-dd', this.timeRange[1]);
      } else {
        this.queryParams.checkBeginDate = null;
        this.queryParams.checkEndDate = null;
      }

      const { data: res } = await this.$http.post("pro/record/have/dates", this.queryParams);
      if (res.code == 0) {
        this.checkDateList = res.data;
        if (this.checkDateList !== null && this.checkDateList.length > 0) {
          this.loadCheckItems(this.checkDateList[0].checkDate);
        } else {
          this.checkRecords = [];
          this.total = 0;
        }
        this.showlineInMap(this.queryParams.lineCode);
      } else {
        this.$message.error(res.msg);
      }
    },
    triggerDate (date) {
      if (!this.common.triggerDate(date, 90)) {
        this.$message.error('查询时间不能超过90天！');
        return false;
      } else {
        return true;
      }
    },
    selectCurrentDateFun(date) {
      this.queryParams.pageNo = 1;
      this.loadCheckItems(date);
    },
    async loadCheckItems(data) {
      this.currentSelectDate = data;
      const { data: res } = await this.$http.post("pro/record/search", {
          projectCode: this.queryParams.projectCode,
          lineCode: this.queryParams.lineCode,
          pageNo: this.queryParams.pageNo,
          pageSize: this.queryParams.pageSize,
          checkBeginDate: data,
          checkEndDate: data,
          checkStatus: this.queryParams.checkStatus
      });
      if (res.code == 0) {
        this.checkRecords = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    centerthis(item) {
      if (item.checkStatus === 0) {
        this.$message.error("该项未巡检");
        return;
      }
      this.$refs.logMapRef.movetocenter(item);
    },
    showlineInMap(lineCode) {
      this.$refs.logMapRef.clearLine();
      this.$refs.logMapRef.getLineItemList(lineCode);
    },
    showLogDetail(data) {
      this.selectLogdetail = data;
      this.checkLogDetailDrawer = true;
    },
    selectCheckLine(data) {
      this.checkLineList.forEach(l => {
        if (l.lineCode === data) {
          this.currentSelectLine = l;
        }
      });
    },
    exportData() {
      if (!this.triggerDate(this.timeRange)) {
        return;
      }

      window.open(this.COMMON_CONFIG.HTTP_API_CTX + 'file/check/download?projectCode=' + this.queryParams.projectCode + '&lineCode=' + this.queryParams.lineCode + '&checkBeginDate=' + this.queryParams.checkBeginDate + '&checkEndDate=' + this.queryParams.checkEndDate + '&checkStatus=' + this.queryParams.checkStatus + '&level=工段级', '_blank');
    }
  }
}
</script>

<style lang="less" scoped>
.logs-list {
  height: calc(100vh - 335px);
  overflow-y: auto;
  overflow-x: hidden;
}
.check-list {
  height: calc(100vh - 365px);
  overflow-y: auto;
  overflow-x: hidden;
}
.marker-map {
  height: calc(100vh - 275px);
}
.date-record {
  width: 100%;
  height: 40px;
  padding: 5px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
  cursor: pointer;
  color: #409EFF;
}
.date-record:hover {
  background-color: #EBEEF5;
}
.item-record:hover {
  background-color: #EBEEF5;
}
</style>